import React from "react";
import moment from "moment";
// import PropTypes from "prop-types";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import Modal from "@components/Modal/Modal";
import CustomerModal from "../CustomerModal/CustomerModal";
import Button from "@components/Button/Button";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from "@helpers/api";
import Select from "../../../../components/Select/Select";
import ActivityIndicator from "../../../../components/ActivityIndicator/ActivityIndicator";

export default class Footer extends React.PureComponent
{
	state = _getInitialState.call(this);

	componentDidMount()
	{
    if(!this.props.hidden) {
      this._unhideTimeout = setTimeout(() => this.setState({hide: false}), 100);
    }
	}

	componentWillUnmount()
	{
		if(this._unhideTimeout) {
      clearTimeout(this._unhideTimeout)
      this._unhideTimeout = -1;
    };      
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.sharingCaseId !== prevProps.sharingCaseId ||
			this.props.showShareDialog !== prevProps.showShareDialog ||
			this.props.shareDialogLoading !== prevProps.shareDialogLoading ||
			this.props.sharingData.length !== prevProps.sharingData.length)
		{
			this.setState({
				caseId: this.props.sharingCaseId,
				sharingModalVisible: this.props.showShareDialog,
				loadingShareDialog: this.props.shareDialogLoading,
				sharingDetails: this.props.sharingData,
				sharingInProgress: false,
				mainAdvisorChanged: this.props.shareError
			});
		}

    if (this.props.hidden !== prevProps.hidden)
		{
      clearTimeout(this._unhideTimeout) // clear any previous timeout to avoid race conditions
      this.setState({hide: this.props.hidden});
		}
	}

	render()
	{
		const {props, state} = this;
		const {
			partner,
			user,
			customer,
			busy,
			view,
			setAppState,
			viewFulfilled,
			showValidationWarning,
			navigationButtons,
			onNavigationBack
		} = props;

		const classes = createClassName("Cob-Footer", {
			"hide": state.hide, "start": view === 1
		});

		const sessionInfo = _getSessionInfo.call(this);
		const sharingDetails = state.sharingDetails;
    const hasCustomersTable = partner.modules.some(module => module?.widgets?.includes("CustomersTable"));

		return (
      <>
        <div className={classes}>
          <div className="Cob-Footer-container">
            {!viewFulfilled && showValidationWarning && (            
              <div className="Cob-Footer-validation">
                <div className="warning">
                  <p>Det finns fält som inte validerar på sidan. Vill du fortsätta ändå?</p>
                  <div className="warning-buttons">
                    <Button
                      type="submit"
                      dataInteractive="ignore-validation-warning"
                      className="Cob-Footer-button"
                      label="Fortsätt"
                      appearance={navigationButtons.next.appearance || "secondary"}
                      filled
                    />
                    <Button
                      className="Cob-Footer-button"
                      label="Avbryt"
                      appearance="medium"
                      onClick={() => setAppState({showValidationWarning: false})}
                      filled
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="Cob-Footer-session">
              <Button
                className="Cob-Footer-button session"
                label={i18n("footer", "manage_session")}
                appearance="medium"
                onClick={this._onOpenSessionModal}
                filled
              />

              {view !== 1 && view !== 2 && !customer._isCaseShared && navigationButtons.sharing && navigationButtons.sharing.display !== false && (
                <Button
                  className="Cob-Footer-button session"
                  label={i18n("footer", "sharing")}
                  appearance="medium"
                  onClick={this._onOpenSharingModal}
                  filled
                />
              )}

              {view > 1 && hasCustomersTable && (
                <Button
                  className="Cob-Footer-button session"
                  label="Customer Details"
                  appearance="secondary"
                  onClick={this._onToggleCustomerModal}
                  filled
                />
              )}
            </div>

            <div className="Cob-Footer-navigation">
              {navigationButtons.next.display !== false && (
                <Button
                  className="Cob-Footer-button"
                  dataInteractive="form-continue-btn"
                  type={navigationButtons.next.willSubmitForm === false ? undefined : "submit"}
                  label={navigationButtons.next.label || i18n("general", "continue")}
                  appearance={viewFulfilled === true && !navigationButtons.next.disabled ? (navigationButtons.next.appearance || "secondary") : "medium"}
                  disabled={(!viewFulfilled && (view === 1 || showValidationWarning))}
                  busy={busy}
                  // onClick below usually does nothing (except for standalone applications behaviour), the main onClick behaviour is set in Cob.js onFormSubmitAsync
                  onClick={!navigationButtons.next.disabled ? navigationButtons.next.onClick : undefined}
                  filled
                />
              )}
              {navigationButtons.back.display !== false && (
                <Button
                  className="Cob-Footer-button"
                  label={navigationButtons.back.label || i18n("general", "previous")}
                  appearance={navigationButtons.back.appearance || "medium"}
                  disabled={busy || view <= 1 || navigationButtons.back.disabled}
                  onClick={!navigationButtons.back.disabled ? onNavigationBack : undefined}
                  filled
                />
              )}
            </div>
          </div>

          <Modal
            view={view}
            visible={state.sessionModalVisible}
            title="Session"
          >
            <div className="Cob-Footer-sessionModal-fields">
              <div className="Cob-Footer-sessionModal-field">
                <span className="label">{i18n("footer", "started")}</span>
                <span className="value">{sessionInfo.startedAt}</span>
              </div>

              <div className="Cob-Footer-sessionModal-field">
                <span className="label">{i18n("footer", "ended")}</span>
                <span className="value">{sessionInfo.endsAt}</span>
              </div>

              <div className="Cob-Footer-sessionModal-field">
                <span className="label">{i18n("footer", "advisor")}</span>
                <span className="value">{sessionInfo.partnerName}</span>
              </div>

              {sessionInfo.customerName && (
                <div className="Cob-Footer-sessionModal-field">
                  <span className="label">{i18n("footer", "customer")}</span>
                  <span className="value">{sessionInfo.customerName}</span>
                </div>
              )}

              {sessionInfo.caseId && (
                <div className="Cob-Footer-sessionModal-field">
                  <span className="label">{i18n("footer", "case")}</span>
                  <span className="value">{sessionInfo.caseId}</span>
                </div>
              )}
            </div>

	          <Modal.ButtonsContainer justifyContent="space-between">
		          <Modal.Button
			          label={i18n("footer", "go_back")}
			          appearance="primary"
			          onClick={this._onCloseSessionModal}
		          />
		          {view !== 1 && (
			          <Modal.Button
				          label={i18n("footer", "end_consultation")}
				          appearance="warning"
				          onClick={this._onRestartSession}
			          />
		          )}
	          </Modal.ButtonsContainer>
          </Modal>
          
          {/* Check why this is rendering when the option button is not visible */}
          {view > 1 && customer.clientData && (
            <CustomerModal
              visible={state.customerModalVisible}
              partner={partner}
              advisor={user}
              client={customer.clientData}
              isSession={true}
              onClose={this._onToggleCustomerModal}
            />
          )}
        </div>
        {/* Sharing Modal needs to stay outside of the main footer container to pop up even if the footer is hidden(start page) */}
        <Modal visible={state.sharingModalVisible} title={i18n("footer", "sharing")}>
          {state.loadingShareDialog && (
            <ActivityIndicator color="#91a0ad" scale={0.75} busy/>
          )}

          {state.mainAdvisorChanged && (
            <p style={{marginTop: "0"}}>
              Huvudrådgivare för ärendet har ändrats och du kan därmed inte längre administrera delning.
            </p>
          )}

          {!state.loadingShareDialog && !state.mainAdvisorChanged && (
            <React.Fragment>
              <p style={{marginTop: "0"}}>
                {i18n("footer", "sharing_text")}
              </p>
              <div className={"sharing-options"}>
                <Select
                  label="Välj rådgivare"
                  options={this.state.availableAdvisors}
                  selectedIndex={this.state.selectedAdvisorIndex}
                  onChange={(option, index) => this.setState({selectedAdvisorIndex: index})}
                  style={{maxWidth: "unset"}}
                />
                <Modal.Button
                  label={"Dela"}
                  appearance="medium"
                  onClick={this._onShareWithAdvisor.bind(this, state.caseId)}
                  disabled={this.state.selectedAdvisorIndex === -1}
                  busy={this.state.sharingInProgress}
                  filled
                />
              </div>
              <div className={"sharing-list"}>
                <strong>{i18n("table_container", "sharing_header")}</strong>
                {sharingDetails.length > 0 && (
                  <ul className={"advisors"}>
                    {sharingDetails.map((option, index) => (
                      <li key={index}>
                        <div className={"name"}>
                          {option.first_name} {option.last_name}

                          {!state.mainAdvisorChanged && (
                            <span className={"action"}
                                style={{marginLeft: "10px"}}
                                onClick={this._onPromoteAdvisor.bind(this, option.advisor_id, state.caseId, option.first_name, option.last_name)}>
                              (Gör till huvudrådgivare)
                            </span>
                          )}
                        </div>
                        <div>
                          {!state.mainAdvisorChanged && (
                            <span className={"action"}
                                onClick={this._onRemoveAdvisor.bind(this, option.advisor_id, state.caseId)}>
                            Ta bort
                          </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

                {(sharingDetails.length === 0) && (
                  <span className={"none"}>{i18n("table_container", "sharing_not_shared")}</span>
                )}
              </div>
            </React.Fragment>
          )}
          <Modal.ButtonsContainer>
            <Modal.Button
              label={i18n("footer", "go_back")}
              appearance="primary"
              onClick={this._onCloseSharingModal}
            />
          </Modal.ButtonsContainer>
        </Modal>
      </>
		);
	}

	// Internal methods
	_onOpenSessionModal = () => this.setState({sessionModalVisible: true});
	_onToggleCustomerModal = () => this.setState({customerModalVisible: !this.state.customerModalVisible});
	_onCloseSessionModal = () => this.setState({sessionModalVisible: false});
	_onRestartSession = async () => {
		const caseId = this.state.caseId == null ? this.props.customer.caseId : this.state.caseId;
		if (caseId)
		{
			console.log("Releasing case lock");
			await api("/case/lock/release", {caseId: caseId});
		}

		this.setState({sessionModalVisible: false}, () => this.props.onRestartSession());
	}

	_onOpenSharingModal = () =>
	{
		if (this.state.caseId == null)
		{
			this.props.onShareOpen(this.props.customer.caseId);
		}
		else
		{
			this.props.onShareOpen(this.state.caseId);
		}
	}

	_onCloseSharingModal = () =>
	{
		this.props.onShareClose();
	}

	_onPromoteAdvisor = async (personalNumber, caseId, firstName, lastName) =>
	{
		if (!window.confirm("Bekräfta att du vill byta ärendets huvudrådgivare till " + firstName + " " + lastName + " genom att trycka på OK"))
		{
			return;
		}

		let advisorSsn = this.props.customer._advisorSsn;

		const {advisor} = this.props.app;
		if (advisorSsn === null && advisor !== undefined && advisor !== null)
		{
			advisorSsn = advisor.ssn;
		}

		try
		{
			await api("/case/sharing/promote", {
				advisor: personalNumber,
				caseId: caseId,
				partnerId: advisorSsn
			});

			if (this.props.customer._caseAdvisor)
			{
				this.props.customer._caseAdvisor.ssn = personalNumber;
				this.props.customer._caseAdvisor.first_name = firstName;
				this.props.customer._caseAdvisor.last_name = lastName;
				this.props.customer._isCaseShared = true;
			}

			this.setState({mainAdvisorChanged: true});
		}
		catch (ex)
		{
      console.log(ex);
		}



	};

	_onRemoveAdvisor = async (personalNumber, caseId) =>
	{
		let advisorSsn = this.props.customer._advisorSsn;

		const {advisor} = this.props.app;
		if (advisorSsn === null && advisor !== undefined && advisor !== null)
		{
			advisorSsn = advisor.ssn;
		}

		await api("/case/sharing/remove", {
			advisor: personalNumber,
			caseId: caseId,
			partnerId: advisorSsn
		});

		const sharingDetails = this.state.sharingDetails;
		const newSharingDetails = [];
		for (let i = 0; i < sharingDetails.length; i++)
		{
			const adv = sharingDetails[i];
			if (adv.advisor_id !== personalNumber)
			{
				newSharingDetails.push(adv);
			}
		}

		this.setState({sharingDetails: newSharingDetails});
	};

	_onShareWithAdvisor = async (caseId) =>
	{
		const advisors = this.props.advisors;
		let advisorSsn = this.props.customer._advisorSsn;

		const {advisor} = this.props.app;
		if (advisorSsn === null && advisor !== undefined && advisor !== null)
		{
			advisorSsn = advisor.ssn;
		}

		if (this.state.selectedAdvisorIndex === -1 || ((this.state.selectedAdvisorIndex + 1) > advisors.length))
		{
			return;
		}

		const selectedAdvisor = advisors[this.state.selectedAdvisorIndex];
		const personalNumber = selectedAdvisor.personal_number;

		this.setState({sharingInProgress: true}, async () =>
		{
			await api("/case/sharing/add", {
				advisor: personalNumber,
				caseId: caseId,
				partnerId: advisorSsn
			});

			const newAdvisor = {};
			for (let i = 0; i < advisors.length; i++)
			{
				if (advisors[i].personal_number === personalNumber)
				{
					newAdvisor.advisor_id = personalNumber;
					newAdvisor.first_name = advisors[i].first_name;
					newAdvisor.last_name = advisors[i].last_name;
				}
			}

			const sharingDetails = this.state.sharingDetails;
			let alreadyShared = false;
			for (let i = 0; i < sharingDetails.length; i++)
			{
				if (sharingDetails[i].advisor_id === personalNumber)
				{
					alreadyShared = true;
					break;
				}
			}

			if (!alreadyShared)
			{
				sharingDetails.push(newAdvisor);
			}

			this.setState({
				selectedAdvisorIndex: -1,
				sharingInProgress: false,
				mainAdvisorChanged: false,
				sharingDetails: sharingDetails,
				advisorsList: buildAdvisorsList.call(this)
			});
		});
	};
}

function buildAdvisorsList()
{
	const {advisors, customer} = this.props;
	const availableAdvisorsList = [];
	for (let i = 0; i < advisors.length; i++)
	{
		const advisor = advisors[i];

		// Don't include self in the list
		if (advisor.personal_number === customer._advisorSsn)
		{
			continue;
		}

		availableAdvisorsList.push({
			label: advisor.first_name + " " + advisor.last_name,
			value: advisor.personal_number
		})
	}

	return availableAdvisorsList;
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	return {
		hide: true,
		caseId: null,
		sessionModalVisible: false,
		customerModalVisible: false,
		sharingModalVisible: this.props.showShareDialog,
		availableAdvisors: buildAdvisorsList.call(this),
		loadingShareDialog: true,
		sharingInProgress: false,
		mainAdvisorChanged: false,
		sharingDetails: [],
		selectedAdvisorIndex: -1
	};
}

function _getSessionInfo()
{
	const {customer, user} = this.props;
	const authToken = Settings.get("AUTH_TOKEN");
	if (!authToken)
	{
		return {partnerName: "", customerName: "", caseId: "", startedAt: null, endsAt: null};
	}
	const tokenData = JSON.parse(atob(authToken.split(".")[1]));

	return {
		partnerName: user.getFullName(),
		customerName: (customer.getData("customer_firstname") + " " + customer.getData("customer_lastname")).trim() || null,
		caseId: customer.caseId,
		startedAt: moment(tokenData.iat * 1000).format("YYYY-MM-DD H:mm:ss"),
		endsAt: moment(tokenData.exp * 1000).format("YYYY-MM-DD H:mm:ss")
	};
}