import { useState, useCallback, useReducer, useEffect } from "react";

import api from "@helpers/api";

import addCustomerModalReducer, {
  initialState
} from "./addCustomerModalReducer";
import validateFields from "@helpers/formValidator";
import { formatSSN } from "@helpers/utils";

const useAddCustomerModal = props => {
  const { open, toggleModal, userData, customer, updateCustomers, allowNoCustomerId } = props;

  const hasId = customer && (customer.id || customer.ssn || customer.orgNumber);  
  const userRole = userData.role;

  const [state, dispatch] = useReducer(addCustomerModalReducer, {
    ...initialState,
    // if adding a new customer and the user is an advisor set the advisorData
    ...(!customer && userRole === "advisor" && {
      advisorData: {
        personal_number: userData.personal_number,
        role: userData.role
      }
    })
  });
  
  const [advisors, setAdvisors] = useState([]);
  const [currentAdvisors, setCurrentAdvisors] = useState([]);
  const [fetchingAdvisors, setFetchingAdvisors] = useState(false);
  const [personalDetailsEnabled, setPersonalDetailsEnabled] = useState(false);

  const canSubmitForm = state.firstName && state.lastName || state.companyName;

  const toggle = () => {
    dispatch({
      type: "toggle"
    });
    return toggleModal();
  };

  const isValidInput = (fieldName, value) => {
    return (value.length > 0 && (!state.inputErrors || !state?.inputErrors[fieldName]));
  };

  const handleInputChange = e => {
    e.preventDefault();
    const key = e.target.name;
    let value = e.target.value;

    dispatch({
      type: "handleInput",
      field: key,
      payload: value
    });

    // if switching person type reset form
    if (key === "type") {
      setPersonalDetailsEnabled(false);
      return dispatch({
        type: "handleSwitchPersonType",
        payload: value
      });
    }
    
    const formData = {
      ...state,
      [key]: value,
    };

    const fieldsToValidate = {...formData};

    let fieldsToAlwaysValidate = [];
    if (personalDetailsEnabled) {
      fieldsToAlwaysValidate = state.type === "person" ?
      ["firstName", "lastName"]
      : ["companyName"];
    }

    if(!allowNoCustomerId) {
      fieldsToAlwaysValidate.push(state.type === "person" ? "ssn" : "orgNumber");
    }

    for (let key in fieldsToValidate) {
      const field = fieldsToValidate[key];

      if (
        !fieldsToAlwaysValidate.includes(key) ||
          fieldsToValidate[key] === "-"
        )
       {
        // only exclude from validation the fields that are empty
        if (fieldsToValidate[key] !== null  && fieldsToValidate[key].length === 0) {
          delete fieldsToValidate[key];
        }
      }
    }

    const inputErrors = validateFields(fieldsToValidate);

    return dispatch({
      type: "handleInputErrors",
      payload: inputErrors || {}
    });
  };

  const setAdvisor = advisor => {
    dispatch({
      type: "setAdvisor",
      payload: advisor
    });
  };

  const handleLookUp = async () =>	{
    const { type, orgNumber, ssn, email} = state;
    const lookupKey = type === "company" ? "orgNumber" : "ssn";
    const lookupValue = type === "company" ? formatSSN(orgNumber) : formatSSN(ssn);
    try
    {
      dispatch({
        type: "lookingUp",
        payload: true
      });
      const lookupResult = await api("/lookup", {[lookupKey]: lookupValue});
      dispatch({
        type: "setLookupResult",
        payload: lookupResult
      });

      setPersonalDetailsEnabled(true);
      const fieldsToValidate = {...lookupResult};
      const fieldsToAlwaysValidate = state.type === "person" ?
      ["firstName", "lastName"]
      : ["companyName"];

      for (let key in fieldsToValidate) {
        const field = fieldsToValidate[key];

        if (
          !fieldsToAlwaysValidate.includes(key) ||
            fieldsToValidate[key] === "-"
          )
        {
          // only exclude from validation the fields that are empty
          if (fieldsToValidate[key] !== null  && fieldsToValidate[key].length === 0) {
            delete fieldsToValidate[key];
          }
        }
      }
      const inputErrors = validateFields(fieldsToAlwaysValidate);
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors || {}
      });
    }
    catch (e)
    {
      dispatch({
        type: "lookingUp",
        payload: false
      });
      console.error(e.message);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });    

    let {
      action,
      type,
    } = state;

    const formData = {...state};    
    // Clean up form data
    const excludedFields = [
      ...(type === "company" ? 
        ["ssn", "firstName", "lastName"]
      : ["orgNumber", "companyName"]),
      "lookingUp",
      "fetching",
      "inputErrors",
      "serverMessage"
    ];
    for (let key in formData) {
      const field = formData[key];

      if (typeof field === "string") {
        formData[key] = field.trim();
      }
      
      if (key === "orgNumber" || key === "ssn") {
        formData[key] = formatSSN(field)
      }

      // remove unecessary fields
      if (excludedFields.includes(key)) {
        delete formData[key];
      }
    }

    dispatch({
      type: "fetching",
      payload: true
    });

    try {
      // will send a POST request to /customer/add or /customer/edit depending on the action
      const client = await api(`/client/${action}`, formData);      
      if (!client)
        return dispatch({
        type: "handleServerError",
        payload: "Something went wrong. Please contact support."
      });

      updateCustomers(client);
      toggle();
    } catch (err) {
      console.log(err, "err");
      // errorHandler.serverError(err);
      const msg = err?.response?.data?.msg;

      if (msg) {
        return dispatch({
          type: "handleServerError",
          payload: msg
        });
      }

      return dispatch({
        type: "handleServerError",
        payload: `Error: ${err?.msg || "Unknown error."}`
      });
    }
  };

  const getAdvisors = async () => {
    try {
      const data = await api("/advisors");
      setAdvisors(data);

      if (customer) {
        const id = customer.client_id || customer.clientId;
        getCurrentAdvisors(id, data);
      }      
    }
    catch (e) {
      console.error(e.message);
    }
  }

  const getCurrentAdvisors = useCallback(async (clientId, advisorList) => {
    try {
      const data = await api("/client/advisors", {
        id: clientId
      });
      setCurrentAdvisors(data.current);
      //remove current advisors from the list of advisors
      const advisorsToExclude = data.current.map(a => a.personal_number);
      const filteredAdvisors = advisorList.filter(a => !advisorsToExclude.includes(a.personal_number));
      setAdvisors(filteredAdvisors);
    }
    catch (e) {
      console.error(e.message);
    }
  },[]);

  const removeAdvisor = async (clientId, advisor) => {
    const advisorId = advisor.personal_number;

    try {
      setFetchingAdvisors(true);
      await api("/client/advisor/remove", {
        clientId,
        advisorId,
      });
      
      // Find and remove advisor from the list of current advisors
      const leCurrentAdvisors = currentAdvisors.filter(a => a.personal_number !== advisorId);
      setCurrentAdvisors(leCurrentAdvisors);

      // Add the removed advisor to the advisor options list
      const advisorList = [...advisors];
      advisorList.push(advisor);
      setAdvisors(advisorList);

      setFetchingAdvisors(false);
    }
    catch (e) {
      console.error(e.message);
    }
  }

  const addAdvisor = async (clientId, advisorId) => {
    try {
      setFetchingAdvisors(true);
      await api("/client/advisor/add", {
        clientId,
        advisorId,
      });

      // Find and add advisor to the list of current advisors
      const advisor = advisors.find(a => a.personal_number === advisorId);
      setCurrentAdvisors([...currentAdvisors, advisor]);
      setFetchingAdvisors(false);

      // Remove the added advisor to the advisor options list
      const advisorList = advisors.filter(a => a.personal_number !== advisorId);
      setAdvisors(advisorList);
    }
    catch (e) {
      console.error(e.message);
    }
  }

  useEffect(() => {
    if (customer) {
      dispatch({
        type: "setCustomer",
        payload: customer
      });
    }
  }, [customer]);

  useEffect(() => {
    if (userRole === "backoffice") {
      getAdvisors();
    }
  }, [userRole, customer]);

  return {
    open,
    state,
    hasId,
    userRole,
    advisors,
    currentAdvisors,
    setAdvisor,
    addAdvisor,
    removeAdvisor,
    fetchingAdvisors,
    toggle,
    allowNoCustomerId,
    personalDetailsEnabled,
    setPersonalDetailsEnabled,
    isValidInput,
    validateFields,
    canSubmitForm,
    handleInputChange,
    handleLookUp,
    handleSubmit
  };
};

export default useAddCustomerModal;
