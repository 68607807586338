import React from "react";

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import api from "@helpers/api";
import i18n from "@helpers/i18n";
import { getCookie } from "@helpers/cookies";
import {createB64Context, stringifyQueryParams, formatSSN} from "@helpers/utils";

// COMPONENTS
import Modal from "@components/Modal/Modal";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import ContentBox from "@components/ContentBox/ContentBox";

import FuturTable from "./FuturTable";


/**
 * Futurkonton
 */
export default class DashboardFutur extends React.PureComponent {

	state = _getInitialState.call(this);

	async componentDidMount() {
		this._isMounted = true;

    try {
      const userCaseList = await _getUserFuturCaseList.call(this);
      if (userCaseList) {
        this.setState({
          isLoading: false,
          userCaseList: userCaseList.batch,
          totalCases: parseInt(userCaseList.total)
        });
      }
    }
    catch (error) {
      this.setState({
        error: true,
        isLoading: false
      });
      console.error(error);
    }
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

  async componentDidUpdate(prevProps, prevState)
	{
    if (this.props.caseFilter.futur !== prevProps.caseFilter.futur) {
      this.setState({
        isLoadingCases: true
      });
      const userCaseList = await _getUserFuturCaseList.call(this);
      this.setState({
        isLoadingCases: false,
        userCaseList: userCaseList.batch,
        totalCases: parseInt(userCaseList.total)
      });
    }
	}

	render() {
		const {props, state} = this;
		const {user, partner, showHeader} = props;

		// /**
		//  * Filter caseList depending on which tab has been selected (if the selected tab is not "all")
		//  */
    // const caseList = props.caseFilter.futur === "all"
    // ? state.userCaseList
    // : state.userCaseList.filter(userCase => userCase.state === props.caseFilter.futur &&
    // (
    //     (props.type === 'customerModal' && props?.client?.id === userCase.ssn) ||
    //     (props.type !== 'customerModal')
    // ));

		return (
      <>
				<ContentBox
         style={
          props.type === "customerModal" ? 
            {
              minHeight: "66vh",
              margin: 0,
              padding: 0,
              overflow: "auto",
              boxShadow: "none"
            }
          :
            {
              width: "98.5%",
              margin: 0,
              marginBottom: "22.5px"
            }
          }
        >
          {showHeader && (
            <h2>
              {i18n("dashboard_selector", "futur")}
            </h2>
          )}
					{state.isLoading && (
						<div style={{padding: "91px 0"}}>
							<ActivityIndicator color="#4cb7d5" busy/>
						</div>
					)}
          {state.error && (
						<div style={{padding: "91px 0"}}>Ett fel uppstod när data laddades, kontakta supporten.
						</div>
					)}
					{!state.error && !state.isLoading && (
            <>
              <div
                className="dashboard-cases"
                style={
                  props.type === "customerModal" ? 
                    { 
                      minHeight: "unset"
                    }
                  :
                    {}
                }
              >
                <FuturTable
                  data={state.userCaseList}
                  totalCases={state.totalCases}
                  isLoadingCases={state.isLoadingCases || false}
                  currentAction={state.currentAction}
                  whitelabel={partner.name}
                  user={user}
                  modalWiget={props.type === "customerModal" ? true : false}
                  caseFilter={props.caseFilter} 
                  setCaseFilter={props.setCaseFilter}
                  onAction={this._onRowAction}
                  getCases={_getUserFuturCaseList.bind(this)}
                />
                {state.isDisabled && (
                  <div className="dashboard-cases-disabled">
                    <ActivityIndicator color="#4cb7d5" busy/>
                  </div>
                )}
              </div>
            </>
					)}
				</ContentBox>

				<Modal visible={state.isReminderModalVisible}>
					<p>En påminnelse om signering har skickats till kunden</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideReminderModal}/>
					</Modal.ButtonsContainer>
				</Modal>

				<Modal visible={state.isDeleteModalVisible}>
					<p>{i18n("dashboard_sessions", "draft_removed")}</p>
					<Modal.ButtonsContainer>
						<Modal.Button label="Ok" appearance="primary" onClick={this._onHideDeleteModal}/>
					</Modal.ButtonsContainer>
				</Modal>
      </>
		);
	}

	_onRowAction = async (action, caseId, ssn, callbackFunc) =>
	{
		const {user, advisor, partner} = this.props;

    this.setState({
      currentAction: action
    });

		if (action === 'delete')
		{
      this.setState({
        isLoadingCases: true
      });
			api("/case/delete", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			});

      const userCaseList = this.state.userCaseList.filter(userCase => userCase.caseId !== caseId);
			this.setState({
        isLoadingCases: false,
        currentAction: 'none',
				isDeleteModalVisible: true,
        userCaseList
			});

			return;
		}

		if (action === 'remind')
		{
			api("/futur/reminder", {
				caseId: caseId
			});

			this.setState({
        currentAction: 'none',
				isReminderModalVisible: true
			});

			return;
		}

		if (action === 'remind_meeting')
		{
			api("/case/reminder", {
				caseId: caseId
			});

			this.setState({
        currentAction: 'none',
				isReminderModalVisible: true
			});

			return;
		}

		if (action === 'download_meeting')
		{
			const rawDataInBase64 = await api("/case/downloadReceipt", {
				caseId: caseId
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

      this.setState({
        currentAction: 'none',
			});

			return;
		}

		if (action === 'download_futur')
		{
			const rawDataInBase64 = await api("/futur/download", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			}, {}, true);

			if (rawDataInBase64 !== "")
			{
				this.downloadFile(caseId + ".pdf", rawDataInBase64);
			}

      this.setState({
        currentAction: 'none',
			});

			return;
		}

		if (action === 'copy')
		{
			const createdCaseId = await api("/case/copy", {
				caseId: caseId, userId: user.ssn, partnerId: advisor.ssn
			}, {}, true);

			this._continueConsultation(createdCaseId.result, partner, ssn, user);
		}

		if (action === 'continue')
		{
			this._continueConsultation(caseId, partner, ssn, user);
		}
	};

	_continueConsultation = (caseId, partner, ssn, user) => {
    if (this.props.type === "customerModal") {      
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll
    }
    
		if (partner.futurcase)
		{
			const encodedContext = createB64Context(partner.partnerId + "client", user, ssn, caseId);
			window.location.href = partner.futurcase + stringifyQueryParams({
				context: encodedContext,
				token: Settings.get("AUTH_TOKEN"),
				key: "futur"
			});
		}
	}

	_onHideReminderModal = () => {
		this.setState({
			isReminderModalVisible: false
		});
	};

	_onHideDeleteModal = () =>
	{
		this.setState({
			isDeleteModalVisible: false
		});
	};

	downloadFile(filename, base64pdf) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		//blob = atob(blob);

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const link = document.createElement('a');
		link.href = "data:application/pdf;base64," + base64pdf;
		link.download = filename;
		link.click();
	}
}

function _getInitialState() {
	return {
		tabSelected: "all",
		isLoading: true,
    isLoadingCases: false,
    currentAction: 'none',
		isDisabled: false,
		isMounted: false,
		isReminderModalVisible: false,
		isRecallModalVisible: false,
		isDeleteModalVisible: false,
		userCaseList: []
	};
}

function _getUserFuturCaseList(page, searchString, order, orderBy) {
	const ssn = this.props.user.ssn;

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage
  const rowsPerPage= cobOptions?.rowsPerPage["futur"] || 10;

	return new Promise(async (resolve, reject) => {
		try {
			console.log("Loading list of Futur cases...");
			const cases = await api("/futur/list", {
				ssn: ssn,
        customerSsn: 
          this.props?.client?.id ? // if a client was passed as a prop (customer modal)
            formatSSN(this.props.client.id, {withDash: false})
          : this.props?.client?.id === "" ? // handle client was passed as a prop but with no ssn set
              ""
            : null, // no ssn passed means it's a main dashboard widget not filtered by customer
        filter: this.props.caseFilter.futur,
        page: page || 0,
        order: order || "desc",
        orderBy: orderBy || "created",
        searchString: searchString || "",
        limit: rowsPerPage === -1 ? 100 : rowsPerPage
			});

			if (this._isMounted) {
				resolve(cases);
			}
		}
		catch (e) {
			reject(e);
		}
	});
}